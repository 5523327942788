import React from 'react'
import PerkinsLogo from "../../assets/images/Perkins .png";//audio
import { motion } from 'framer-motion';

import '../../styles/slider.css';



function Product_Perkins() {
  return (
    <div className='card display-flex' style={{padding: '8px 20px',marginLeft:'20px'}}>
    <motion.img whileHover={{ scale: 0.9 }}  className='product--image' src={PerkinsLogo} alt="" />

  </div>
  )
}

export default Product_Perkins