import React from 'react'
import productColors from "../../assets/images/Colors Black.png";//audio
import { motion } from 'framer-motion';

import '../../styles/slider.css';


function Product_marqueColors() {
  return (
    <div className='card display-flex' style={{padding: '8px 20px',marginLeft:'20px'}}>
    <motion.img whileHover={{ scale: 0.9 }}  className='product--image' src={productColors} alt="" />
    
  </div>
  )
}

export default Product_marqueColors