

import React from 'react';

import { motion } from 'framer-motion';
import '../../styles/product-card.css';
import { Col } from "reactstrap";
import { Link } from 'react-router-dom';
import { toast }  from 'react-toastify' ; 

import { useDispatch } from 'react-redux';
import { cartActions } from '../../redux/slices/cartSlice';

import { RiAddCircleFill } from "react-icons/ri";


const ProductCard = ({ item }) => {


const dispatch = useDispatch();
const addToCart = () => {
  dispatch(
    cartActions.addItem({
      id: item.id,
      productName: item.productName,
      price: item.price,
      priceNew: item.priceNew,
      imgUrl: item.imgUrl,
      message: item.message,
    })
  );

  toast.success('Product added successfully')
};

const idsSpecials = [1, 3];

  return (
    <Col lg='3' md='4' className='md-2'>
        <div className="product__item">
            <div className='product__img'>
            <Link to={`/shop/${item.id}`}><motion.img whileHover={{ scale: 0.9 }} src={item.imgUrl} alt="" /></Link>
        </div>
            <div className='p-2 product__info'>
            <h3 className='product__name'><Link to={`/shop/${item.id}`}>{item.productName}</Link></h3>
            <span>{item.category}</span>
            </div>
        
        <div className="product__card-bottom d-flex align-items-center 
        justify-content-between p-2">
            <span className="price">${item.price}</span>
            <span className='priceNew'>{item.priceNew}</span><br /><br />
            <motion.span whileTap={{scale: 1.2}} onClick={addToCart}>
            <RiAddCircleFill style={{color:'crimson', fontSize:'200%'}} />
              </motion.span>
        </div>
        <p className='message'><mark>{item.message}</mark></p>
    </div>
    </Col>
  );
};

export default ProductCard;