import React from 'react'
import productHero from "../../assets/images/Hero Baby.png";//audio
import { motion } from 'framer-motion';

import '../../styles/slider.css';


function Product_marqueHer() {
  return (
    <div className='card display-flex' style={{padding: '8px 20px',marginLeft:'20px'}}>
    <motion.img whileHover={{ scale: 0.9 }}  className='product--image' src={productHero} alt="" />
   
  </div>
  )
}

export default Product_marqueHer