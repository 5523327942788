

import React from 'react';
import '../styles/cart.css';
import Helmet from '../components/Helmet/Helmet';
import CommonSection from '../components/Ul/CommonSection';
import { Container, Row, Col } from 'reactstrap';


import { motion } from 'framer-motion';
import { cartActions } from '../redux/slices/cartSlice';
import { useSelector, useDispatch } from 'react-redux';

import { Link } from 'react-router-dom';
import WEBCOVER from '../assets/images/WEBSITE COVER (1).jpg'

const Cart = () => {


const cartItems = useSelector((state) => state.cart.cartItems);
const totalAmount = useSelector(state=> state.cart.totalAmount);
  
  return ( 
  <Helmet>
    <img src={WEBCOVER} alt="" />
    {/**<CommonSection title='Shopping Cart'/> */}
    <section>
      <Container>
        <Row>
          <Col lg='9'>
            {cartItems.length === 0 ? (
            <h2 className='fs-4 text-center'>Rien n'est ajouté dans le panier</h2>
            ) : (
              <table className='table bordered'>
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Title</th>
                  <th>Price</th>
                  <th>Qty</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {
                  cartItems.map((item,index)=>(
                      <Tr item={item} key={index} />
                ))
                }
              </tbody>
            </table>
            )}
          </Col>

          <Col lg='3'>
            <div>
              <h6 className='d-flex align-items-center justify-content-between'>
                Subtotal
                <span className='fs-4 fw-bold'>${totalAmount}</span>
              </h6>
              
            </div>
            <p className='fs-6 mt-2'>Disponible dans tous les magasins Fouani Congo</p>
            <div>
            <button className="buy__btn w-100">
              <Link to='/login'>Contactez-nous pour la vente en ligne
              </Link>
              </button>
              <button className="buy__btn w-100 mt-3">
              <Link to='/shop'>Continuer le Shopping en ligne
              </Link>
              </button>  
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  </Helmet>
 );
};


const Tr = ({ item })=> {

  const dispatch = useDispatch()

  const deleteProduct = ()=>{
    dispatch(cartActions.deleteItem(item.id))
  }
  return (
  <tr>
    <td>
      <img src={item.imgUrl} alt="" />
    </td>
    <td>{item.productName}</td>
    <td>${item.price}</td>
    <td>{item.quantity}px</td>
    <td>
      <motion.i 
      whileTap={{ scale:1.2 }} 
      onClick={deleteProduct}
      className='ri-delete-bin-line'
      >
      </motion.i>
    </td>
  </tr>
  );
};

export default Cart;