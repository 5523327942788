import React from 'react'
import productDecakila from "../../assets/images/decakila red.png";//audio
import { motion } from 'framer-motion';

import '../../styles/slider.css';


function Product_marqueDec() {
  return (
    <div className='card display-flex' style={{padding: '8px 20px',marginLeft:'20px', alignItems:'center'}}>
    <motion.img whileHover={{ scale: 0.9 }}  className='product--image' src={productDecakila} alt="" />
  </div>
  )
}

export default Product_marqueDec